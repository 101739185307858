import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row, Button, FormGroup, Label, Col,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { isEmpty, map, get, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import toastr from 'toastr';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import socketIOClient from 'socket.io-client';
// import { fetchUpdateMachine } from '../../Redux/Helpers/fetch';
import { formatLastConnectDate } from '../../utils';
import {
  RemoteControl, RCDoorPosition, RCMessageType, RCRequestCode, RCResponseCode,
} from '../../remoteControlContants';
import loadingPage from '../../Redux/Actions/loading';

library.add(faCircle);

function replaceAll(str, pattern, replacement) {
  return str.split(pattern).join(replacement);
}

class MachineInfo extends Component {
  constructor() {
    super();
    this.state = {
      dropdownDoorStatus: false,
      // dropdownDoorPosition: false,
      dropdownRemoteType: false,
      dropdownKeyPosition: false,
      modal: false,
      doorStatus: 'Open',
      doorPosition: 'Upper',
      isRequesting: false,
      isRequestingUpdate: false,
      sessionID: '',
      time: '',
      remoteType: 'Door',
      keyPosition: 1,
      isOpenVideoStreamRequesting: false,
    };
    this.requestTimeout = undefined;
  }

  ab2str = buf => String.fromCharCode.apply(null, new Uint8Array(buf))

  componentDidMount = () => {
    this.socket = socketIOClient(RemoteControl.SOCKET_URL, { transports: ['websocket'] });

    this.socket.on(RemoteControl.RESPONSE_FROM_MACHINE_TOPIC, (res) => {
      console.log('response from machine ', res);
      this.handleResponseFromMachine(res);
      if (this.requestTimeout) {
        clearTimeout(this.requestTimeout);
      }
      this.setState({ isRequesting: false, isOpenVideoStreamRequesting: false });
    });

    this.handleStartConference();
  }

  componentWillUnmount = () => {
    this.socket.disconnect();
    localStorage.setItem(`isInitStream_${RemoteControl.PROJECT_ID}`, false);
  }

  componentDidUpdate = (prevProps) => {
    const { dataMachines } = this.props;

    if (!isEmpty(dataMachines) && !isEqual(dataMachines, prevProps.dataMachines)) {
      if (prevProps.dataMachines.location !== dataMachines.location) {
        this.setState({ location: dataMachines.location });
      }
    }

    this.handleStartConference();
  }

  toggleModalEditMachine = () => {
    this.setState(prevState => ({ modalEditMachine: !prevState.modalEditMachine }));
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  }

  toggleDoorStatus = () => {
    const { dropdownDoorStatus } = this.state;
    this.setState({ dropdownDoorStatus: !dropdownDoorStatus });
  }

  // toggleDoorPosition = () => {
  //   const { dropdownDoorPosition } = this.state;
  //   this.setState({ dropdownDoorPosition: !dropdownDoorPosition });
  // }

  toggleRemoteType = () => {
    const { dropdownRemoteType } = this.state;
    this.setState({ dropdownRemoteType: !dropdownRemoteType });
  }

  toggleKeyPosition = () => {
    const { dropdownKeyPosition } = this.state;
    this.setState({ dropdownKeyPosition: !dropdownKeyPosition });
  }

  onRequestTimeout = () => {
    toastr.error('Request timeout!', 'Error');
    this.setState({ isRequesting: false });
  }

  handleResponseFromMachine = (res) => {
    const {
      sessionID, time,
    } = this.state;

    if (res.message_id === RCRequestCode.OPEN_VIDEO) {
      this.setState({ isOpenVideoStreamRequesting: false });
      this.handleStartConference();
      if (this.requestTimeout) {
        clearTimeout(this.requestTimeout);
      }
    }

    if ([RCRequestCode.CLOSE_VIDEO, RCResponseCode.STOP_STREAM].includes(res.message_id)) {
      this.handleStopConference();
      this.setState({ isOpenVideoStreamRequesting: false });
      if (this.requestTimeout) {
        clearTimeout(this.requestTimeout);
      }
    }

    if (sessionID !== res.session_id) {
      return;
    }

    if ((res.time - time) > 15) {
      return;
    }

    if (res.response_code === RCResponseCode.BUSY) {
      toastr.warning('The machine is busy!', 'Warning');
      return;
    }

    if (res.response_code === RCResponseCode.FAIL) {
      toastr.error('Request failed!', 'Error');
      return;
    }

    if (res.response_code === RCResponseCode.OK) {
      toastr.success('The request was successful!', 'Success');
    }
  }

  requestToMachine = () => {
    const { dataMachines } = this.props;
    const {
      modal, doorStatus, doorPosition, remoteType,
    } = this.state;
    const sessionID = uuidv4();
    const time = Math.floor(moment().utcOffset(0).valueOf() / 1000);
    let position = RCDoorPosition.UPPER;
    let status = false;
    let keyPosition = 1;
    let data = {};

    if (remoteType === 'Door') {
      if (doorPosition === 'Lower') {
        position = RCDoorPosition.LOWER;
      }

      if (doorStatus === 'Open') {
        status = true;
      }

      data = {
        protocol_version: 'v1.0.0',
        machine_id: dataMachines.serialMachine,
        session_id: sessionID,
        message_type: RCMessageType.REQ,
        message_id: RCRequestCode.TOGGLE_DOOR,
        time,
        position,
        open: status,
      };
    } else if (remoteType === 'Key') {
      keyPosition = this.state.keyPosition;

      data = {
        protocol_version: 'v1.0.0',
        machine_id: dataMachines.serialMachine,
        session_id: sessionID,
        message_type: RCMessageType.REQ,
        message_id: RCRequestCode.RELEASE_KEY,
        time,
        position_key: keyPosition,
      };
    } else {
      data = {};
    }

    console.log('request from web ', data);

    this.socket.emit(RemoteControl.REQUEST_FROM_WEB_TOPIC, JSON.stringify(data));
    if (this.requestTimeout) {
      clearTimeout(this.requestTimeout);
    }
    this.requestTimeout = setTimeout(this.onRequestTimeout, 17000);

    this.setState({
      modal: !modal,
      isRequesting: true,
      sessionID,
      time,
    });
  }

  renderRemoteTypeItem = () => {
    const statusItems = ['Door', 'Key'];

    return (
      map(statusItems, (value, index) => (
        <DropdownItem
          name="remoteType"
          key={index}
          onClick={() => { this.setState({ remoteType: value }); }}
        >
          {value}
        </DropdownItem>
      ))
    );
  }

  renderKeyPositionItem = () => {
    const statusItems = Array.from({ length: 50 }, (v, i) => i + 1);

    return (
      map(statusItems, (value, index) => (
        <DropdownItem
          name="keyPosition"
          key={index}
          onClick={() => { this.setState({ keyPosition: value }); }}
        >
          {value}
        </DropdownItem>
      ))
    );
  }

  renderDoorPositionItem = () => {
    const statusItems = ['Upper', 'Lower'];

    return (
      map(statusItems, (value, index) => (
        <DropdownItem
          name="doorPosition"
          key={index}
          onClick={() => { this.setState({ doorPosition: value }); }}
        >
          {value}
        </DropdownItem>
      ))
    );
  }

  renderDoorStatusItem = () => {
    const statusItems = ['Open', 'Close'];

    return (
      map(statusItems, (value, index) => (
        <DropdownItem
          name="doorStatus"
          key={index}
          onClick={() => { this.setState({ doorStatus: value }); }}
        >
          {value}
        </DropdownItem>
      ))
    );
  }

  onOpenVideoStreamMachine = () => {
    const { dataMachines, onStartConference } = this.props;
    const sessionID = uuidv4();
    const time = Math.floor(moment().utcOffset(0).valueOf() / 1000);

    const data = {
      protocol_version: 'v1.0.0',
      machine_id: dataMachines.serialMachine,
      session_id: sessionID,
      message_type: RCMessageType.REQ,
      message_id: dataMachines.isOpenStream ? RCRequestCode.CLOSE_VIDEO : RCRequestCode.OPEN_VIDEO,
      time,
      room_name: replaceAll(`${RemoteControl.PROJECT_ID}_${dataMachines.serialMachine}`, '-', '_'),
    };

    console.log('onOpenVideoStreamMachine', dataMachines.isOpenStream ? 'Close stream' : 'Open stream');

    this.socket.emit(RemoteControl.REQUEST_FROM_WEB_TOPIC, JSON.stringify(data));
    if (this.requestTimeout) {
      clearTimeout(this.requestTimeout);
    }
    this.requestTimeout = setTimeout(this.onRequestTimeout, 17000);

    if (dataMachines.isOpenStream) {
      this.handleStopConference();
    } else {
      onStartConference(
        replaceAll(`${RemoteControl.PROJECT_ID}_${dataMachines.serialMachine}`, '-', '_'),
        dataMachines.serialMachine,
      );
      this.setState({ isOpenVideoStreamRequesting: true });
    }
  }

  renderVideoStreaming = () => {
    const { tracks, dataMachines } = this.props;

    if (dataMachines.isOpenStream) {
      const trackerList = Object.keys(tracks) || [];
      const track = trackerList.reverse().find(id => {
        const participants = get(tracks[id], 'track.conference.participants', {});
        return (
          !isEmpty(participants) &&
          !isEmpty(participants[id]) &&
          participants[id]._displayName ===
            replaceAll(
              `Client${RemoteControl.PROJECT_ID}_${dataMachines.serialMachine}`,
              '-',
              '_',
            )
        )
      });

      if (!isEmpty(track) && !isEmpty(tracks[track])) {
        const { idx, participant } = tracks[track];
        return (
          <video autoPlay id={`${participant}video${idx}`} className="video-streaming" />
        );
      }

      return (
        <React.Fragment>
          <video className="loading" />
          <div className="loader">loading...</div>
        </React.Fragment>
      );
    }
  }

  handleStartConference = () => {
    const { dataMachines, onStartConference } = this.props;

    const isInitStream = JSON.parse(localStorage.getItem(`isInitStream_${RemoteControl.PROJECT_ID}`));
    if (dataMachines.isOpenStream && !isInitStream) {
      onStartConference(
        replaceAll(`${RemoteControl.PROJECT_ID}_${dataMachines.serialMachine}`, '-', '_'),
        dataMachines.serialMachine,
      );
    }
  }

  handleStopConference = () => {
    const { dataMachines, onStopConference } = this.props;

    const isInitStream = JSON.parse(localStorage.getItem(`isInitStream_${RemoteControl.PROJECT_ID}`));
    if (dataMachines.isOpenStream && isInitStream) {
      onStopConference(dataMachines.serialMachine);
    }
  }

  render = () => {
    const { dataMachines } = this.props;
    const {
      modal, dropdownDoorStatus,
      doorStatus, isRequesting, isRequestingUpdate,
      dropdownRemoteType, dropdownKeyPosition, remoteType, keyPosition,
      isOpenVideoStreamRequesting,
    } = this.state;
    if (dataMachines && !isEmpty(dataMachines)) {
      return (
        <div className={dataMachines.isOpenStream ? '' : 'disable-streaming'}>
          <Row className="mt-3">
            <span className="text__content">
              <FontAwesomeIcon
                icon="circle"
                className={dataMachines.status.toLowerCase() === 'online'
                  ? 'status-circle --green'
                  : 'status-circle --gray'
                }
              />
              {dataMachines.status}
            </span>
          </Row>
          <div>
            <Row style={{ marginLeft: '0rem' }}>
              <div>
                <span className="text__title text__title--info">
                  {/* Upper door: */}
                  Door:
                </span>
                <span className="text__content">
                  {dataMachines.upperDoor}
                </span>
              </div>
            </Row>
            {/* <Row style={{ marginLeft: '0rem' }}>
              <div>
                <span className="text__title text__title--info">
                        Lower door:
                </span>
                <span className="text__content">
                  {
                    dataMachines.upperDoor
                  }
                </span>
              </div>
            </Row> */}
          </div>
          <Row style={{ marginLeft: '0rem' }}>
            <div>
              <span className="text__title text__title--info">
                Fire sensor:
              </span>
              <span className="text__content">
                {dataMachines.fireSensor}
              </span>
            </div>
          </Row>
          <Row style={{ marginLeft: '0rem' }}>
            <div>
              <span className="text__title text__title--info">
                Last connect:
              </span>
              <span className="text__content info--content__block">
                {formatLastConnectDate(dataMachines.lastConnect)}
              </span>
            </div>
          </Row>
          <Row style={{ marginLeft: '0rem' }}>
            <div>
              <span className="text__title text__title--info">
                IP address:
              </span>
              <span className="text__content info--content__block">
                {dataMachines.publicIpAddress}
              </span>
            </div>
          </Row>
          <Row style={{ marginLeft: '0rem' }}>
            <div style={{ width: '100%' }}>
              <span className="text__title text__title--info">
                Location:
              </span>
              <span className="text__content info--content__block">
                {dataMachines.location}
              </span>
            </div>
          </Row>
          <Row style={{ marginLeft: '0rem', marginTop: '1.5rem' }}>
            <div>
              <Button
                color="primary"
                disabled={isRequesting}
                onClick={this.toggleModal}
                className="mr-2 mb-2"
              >
                {isRequesting ? 'Requesting! Please wait...' : 'Remote Control'}
              </Button>
              <Button
                color="primary"
                disabled={isOpenVideoStreamRequesting || dataMachines.status === 'Offline'}
                onClick={this.onOpenVideoStreamMachine}
                className="mb-2"
              >
                {dataMachines.isOpenStream ? 'Close Video Stream': (isOpenVideoStreamRequesting
                  ? 'Requesting! Please wait...'
                  : 'Open Video Stream')}
              </Button>
            </div>
          </Row>
          <Modal isOpen={modal} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Remote control</ModalHeader>
            <ModalBody>
              <FormGroup row className="booking-detail">
                <Label className="text__content text__title--info mb-0">
                    Remote type:
                </Label>
                <Col sm={8}>
                  <Dropdown
                    isOpen={dropdownRemoteType}
                    toggle={this.toggleRemoteType}
                  >
                    <DropdownToggle caret>
                      {remoteType}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {this.renderRemoteTypeItem()}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </FormGroup>
              {remoteType === 'Door' ? (
                <div>
                  {/* <FormGroup row className="booking-detail">
                    <Label className="text__content text__title--info mb-0">
                    Door position:
                    </Label>
                    <Col sm={8}>
                      <Dropdown isOpen={dropdownDoorPosition} toggle={this.toggleDoorPosition}>
                        <DropdownToggle caret>
                          {doorPosition}
                        </DropdownToggle>
                        <DropdownMenu right>
                          {this.renderDoorPositionItem()}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </FormGroup> */}
                  <FormGroup row className="booking-detail">
                    <Label className="text__content text__title--info mb-0">
                    Door status:
                    </Label>
                    <Col sm={8}>
                      <Dropdown
                        isOpen={dropdownDoorStatus}
                        toggle={this.toggleDoorStatus}
                      >
                        <DropdownToggle caret>
                          {doorStatus}
                        </DropdownToggle>
                        <DropdownMenu right>
                          {this.renderDoorStatusItem()}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </FormGroup>
                </div>

              ) : (
                <FormGroup row className="booking-detail">
                  <Label className="text__content text__title--info mb-0">
                    Key position:
                  </Label>
                  <Col sm={8}>
                    <Dropdown isOpen={dropdownKeyPosition} toggle={this.toggleKeyPosition}>
                      <DropdownToggle caret>
                        {keyPosition}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {this.renderKeyPositionItem()}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.requestToMachine}>Request</Button>
            </ModalFooter>
          </Modal>
          <div id="player">
            {this.renderVideoStreaming()}
          </div>
        </div>
      );
    }

    return null;
  }
}

MachineInfo.defaultProps = {
  dataMachines: {},
};

MachineInfo.propTypes = {
  dataMachines: PropTypes.shape({
    location: PropTypes.String
  }),
  loadingPage: PropTypes.func.isRequired,
  tracks: PropTypes.objectOf(PropTypes.object),
  onStartConference: PropTypes.func,
  onStopConference: PropTypes.func,
};

const mapDispatchToProps = {
  loadingPage,
};

export default connect(null, mapDispatchToProps)(MachineInfo);
